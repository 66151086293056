import {ApolloProvider} from '@apollo/client'
import DataLayer from '@/components/common/DataLayer'
import Insights from '@/components/common/Insights'
import WordPressProvider from '@/components/common/WordPressProvider'
import AdsProvider from '@/components/organisms/Ad/AdsProvider'
import getAds from '@/functions/wordpress/ads/getAds'
import formatMenus from '@/functions/wordpress/menus/formatMenus'
import getMenus from '@/functions/wordpress/menus/getMenus'
import formatSocialSeoData from '@/functions/wordpress/seo/formatSocialSeoData'
import getSeoFields from '@/functions/wordpress/seo/getSeoFields'
import getSiteSettings from '@/functions/wordpress/settings/getSiteSettings'
import {FontStyles} from '@/lib/fonts'
import constructGoogleAds from '@/lib/providers/googleAds'
import {useWpApollo} from '@/lib/wordpress/connector'
import {GoogleTagManager} from '@next/third-parties/google'
import '@/styles/index.css'
import {SessionProvider as NextAuthProvider} from 'next-auth/react'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

let globalDataCache

/**
 * Render the App component.
 * @author Americaneagle.com
 * @param  {object}  props             The component attributes as props.
 * @param  {object}  props.Component   Page component to display.
 * @param  {object}  props.pageProps   Page component props.
 * @param  {object}  props.globalProps Navigation props.
 * @return {Element}                   The App component.
 */
export default function App({Component, pageProps, globalProps}) {
  // Re-hydrate the cache variable client-side.
  useEffect(() => {
    globalDataCache = globalProps
  }, [globalProps])

  /**
   * Wrap the app in the ApolloProvider component.
   * @see https://www.apollographql.com/docs/react/api/react/hooks/#the-apolloprovider-component
   */
  const apolloClient = useWpApollo(pageProps)

  const componentProps = {
    ...pageProps,
    post: {
      ...pageProps?.post,
      seo: {
        ...pageProps?.post?.seo,
        siteTitle: globalProps?.generalSettings?.title,
        ...formatSocialSeoData(globalProps?.siteSeo?.meta?.social)
      }
    }
  }

  // Initialize state for WordPress context provider.
  const [wp] = useState({
    menus: formatMenus(globalProps?.menus, globalProps?.megaMenuItems)
  })

  // Get a preconfigured list of GPT ad data.
  const googleAds = constructGoogleAds(
    globalProps?.adSlots?.nodes || [],
    pageProps?.post?.blocks || []
  )

  return (
    <NextAuthProvider>
      <ApolloProvider client={apolloClient}>
        <AdsProvider ads={googleAds} enableLazyload>
          <WordPressProvider value={wp}>
            <>
              {!!pageProps?.preview && (
                // TODO -- abstract this to a component.
                <p>
                  This page is a preview.{' '}
                  <a href={'/api/exit-preview'}>Exit preview mode</a>
                </p>
              )}
              <GoogleTagManager
                gtmId={
                  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
                    ? globalProps?.headlessConfig?.additionalSettings?.gtmIdProd
                    : globalProps?.headlessConfig?.additionalSettings?.gtmIdDev
                }
              />
              <DataLayer post={componentProps.post} />
              <Insights />
              <FontStyles />
              <Component {...componentProps} />
            </>
          </WordPressProvider>
        </AdsProvider>
      </ApolloProvider>
    </NextAuthProvider>
  )
}

App.getInitialProps = async () => {
  if (globalDataCache) {
    return {globalProps: globalDataCache}
  }

  const [
    {
      menus: {menus, megaMenuItems}
    },
    {adSlots},
    {
      seoFields: {siteSeo}
    },
    {
      siteSettings: {generalSettings, headlessConfig}
    }
  ] = await Promise.all([
    getMenus(),
    getAds(),
    getSeoFields(),
    getSiteSettings()
  ])

  globalDataCache = {
    menus,
    megaMenuItems,
    adSlots,
    generalSettings,
    siteSeo,
    headlessConfig
  }

  return {
    globalProps: {
      menus,
      megaMenuItems,
      adSlots,
      generalSettings,
      siteSeo,
      headlessConfig
    }
  }
}

App.propTypes = {
  Component: PropTypes.any.isRequired,
  globalProps: PropTypes.object.isRequired,
  pageProps: PropTypes.object.isRequired
}
