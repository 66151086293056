const REFRESH_KEY = 'refresh'
const REFRESH_VALUE = 'true'

// Number of seconds to wait after the slot becomes viewable.
const SECONDS_TO_WAIT_AFTER_VIEWABILITY = 60

/**
 * Instantiate the global googletag object.
 * @return {object} The googletag object.
 */
function googleTag() {
  const global = window

  global.googletag = global.googletag || {}
  global.googletag.cmd = global.googletag.cmd || []

  return global.googletag
}

export const dfp = {
  createSlots: (ads, enableLazyload, enableRefresh = true) => {
    googleTag().cmd.push(() => {
      googleTag().pubads().collapseEmptyDivs()

      ads.forEach(({slotId, divId, sizeMappings}) => {
        let responsiveMappings = null
        let mappings = sizeMappings

        if (sizeMappings.length > 0) {
          const [firstSizeMapping] = sizeMappings

          if (
            typeof firstSizeMapping === 'object' &&
            !!firstSizeMapping.breakpoint &&
            !!firstSizeMapping.sizes
          ) {
            const sizeMapping = googleTag().sizeMapping()

            mappings = []

            sizeMappings.forEach(({breakpoint, sizes}) => {
              sizeMapping.addSize(breakpoint, sizes)

              const [firstSize] = sizes

              if (!!firstSize && Array.isArray(firstSize)) {
                mappings.push(...sizes)
              } else {
                mappings.push(sizes)
              }
            })

            responsiveMappings = sizeMapping.build()
          }
        }

        const slot = googleTag()
          .defineSlot(slotId, mappings, divId)
          .setTargeting(REFRESH_KEY, REFRESH_VALUE)
          .addService(googleTag().pubads())

        if (responsiveMappings) {
          slot.defineSizeMapping(responsiveMappings)
        }
      })

      if (enableRefresh) {
        googleTag()
          .pubads()
          .addEventListener('impressionViewable', (event) => {
            const slot = event.slot

            if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
              setTimeout(() => {
                googleTag().pubads().refresh([slot])
              }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000)
            }
          })
      }

      if (enableLazyload) {
        // Enable lazyload with some good defaults
        googleTag().pubads().enableLazyLoad({
          fetchMarginPercent: 500,
          renderMarginPercent: 200,
          mobileScaling: 2.0
        })
      }

      googleTag().enableServices()
    })
  },
  showSlot: (divId) => {
    googleTag().cmd.push(() => {
      googleTag().display(divId)
    })
  },
  removeSlots: () => {
    googleTag().cmd.push(() => {
      googleTag().destroySlots()
    })
  }
}
