import PropTypes from 'prop-types'
import {createContext, useContext, useEffect, useState} from 'react'
import Script from 'next/script'
import {dfp} from './dfp'

// Initialize context object.
export const AdsContext = createContext()

/**
 * Export useContext Hook.
 * @return {Function} WordPress Context
 */
export function useAdsContext() {
  const context = useContext(AdsContext)

  if (!context) {
    throw new Error(
      `[AdsContext]: You forgot to wrap your component with AdsProvider`
    )
  }

  return context
}

/**
 * Provide Ads Context for components.
 * @param  {object}  props                The component attributes as props.
 * @param  {Array}   props.ads            The ad items.
 * @param  {object}  props.children       React children.
 * @param  {boolean} props.debug          Whether to enable debug mode.
 * @param  {boolean} props.enableLazyload Whether to enable lazy loading.
 * @param  {boolean} props.enableRefresh  Whether to enable refresh.
 * @return {Element}                      The child elements wrapped in a context provider.
 */
export default function AdsProvider({
  ads,
  children,
  debug = false,
  enableLazyload = true,
  enableRefresh = true
}) {
  const [isLoading, setIsLoading] = useState(true)

  // Create ad slots
  useEffect(() => {
    setIsLoading(true)

    dfp.removeSlots()
    dfp.createSlots(ads, enableLazyload, enableRefresh)

    setIsLoading(false)
  }, []) // eslint-disable-line

  // Enable debug console if possible
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (!!debug && !searchParams.has('google_console')) {
      searchParams.append('google_console', '1')
      window.location = `${window.location.pathname}?${searchParams}`
    }

    if (!debug && searchParams.has('google_console')) {
      searchParams.delete('google_console')

      const search = `${searchParams}`.length > 0 ? `?${searchParams}` : ''
      window.location = `${window.location.pathname}${search}`
    }
  }, [debug])

  return (
    <>
      <AdsContext.Provider value={{isLoading}}>{children}</AdsContext.Provider>
      <Script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        async
      />
    </>
  )
}

AdsProvider.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      divId: PropTypes.string,
      slotId: PropTypes.string,
      sizeMappings: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    })
  ),
  children: PropTypes.object,
  debug: PropTypes.bool,
  enableLazyload: PropTypes.bool,
  enableRefresh: PropTypes.bool
}
